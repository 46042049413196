export const builderStepsData = [
  {
    id: 1,
    name: 'location',
    active: false,
    complete: false,
    next: false,
    step_name: 'location',
  },
  {
    id: 2,
    name: 'species',
    active: false,
    complete: false,
    next: false,
    step_name: 'species',
  },
  {
    id: 3,
    name: 'tactics',
    active: false,
    complete: false,
    next: false,
    step_name: 'tactics',
  },
  {
    id: 4,
    name: 'temperature',
    active: false,
    complete: false,
    next: false,
    step_name: 'temperature',
  },
  {
    id: 5,
    name: 'gender',
    active: false,
    complete: false,
    next: false,
    step_name: 'gender',
  },
  {
    id: 6,
    name: 'results',
    active: false,
    complete: false,
    next: false,
    step_name: 'hunt_type',
  },
]

export const builderSystem = {
  location: '',
  species: '',
  tactics: '',
  temperature: '',
  gender: '',
}
